import * as React from "react"
import {graphql} from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import PageHeaderBlock from "../components/PageHeaderBlock";
import {Col, Container, Row} from "react-bootstrap";
import {StaticImage} from "gatsby-plugin-image";
import ArticleWidget from "../components/widgets/ArticleWidget";
import ArticleWidget2 from "../components/widgets/ArticleWidget2";
import Breadcrumbs from "../components/Breadcrumbs";

export default function SozdaniyeUnikalnogoIInformativnogoKontenta() {
    const { t } = useTranslation();

    return (
        <Layout>
            <Seo title={t('post14Title')} description={t('post14Description')} />
            <PageHeaderBlock h1="post14H1" />
            <Container fluid className="px-flex">
                <Breadcrumbs firstChild="blogPageWord" firstChildUrl={'/blog'} secondChild="seoBlog" secondChildUrl={'/category/pravilnoe-seo'} thirdChild="post14H1" />
                <Row className="my-3 my-xl-4 my-xxl-5">
                    <Col xxl={2} className="mb-2 mb-xl-3 mb-xxl-0 pb-1 pb-xl-0"><ArticleWidget /></Col>
                    <Col md={8} xxl={7} className="blog-post">
                        <StaticImage
                            src="../images/posts/post14/sozdaniye-unikalnogo-i-informativnogo-kontenta.png"
                            width={1650}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt={t('post14H1')}
                            className="blog-post__img w-100 mb-2 mb-xl-3 mb-xxl-4"
                            placeholder="blurred"
                        />
                        <div dangerouslySetInnerHTML={{__html: t('post14Text')}} />
                        {/*<center>*/}
                        {/*    <StaticImage*/}
                        {/*        src="../images/posts/post14/etapy-razrabotki.png"*/}
                        {/*        width={1600}*/}
                        {/*        quality={95}*/}
                        {/*        formats={["auto", "webp", "avif"]}*/}
                        {/*        alt={t('post14H1')}*/}
                        {/*        className="mb-2 mb-xl-3 mb-xxl-4"*/}
                        {/*    />*/}
                        {/*</center>*/}
                        <div dangerouslySetInnerHTML={{__html: t('post14Text1')}} />
                        <div dangerouslySetInnerHTML={{__html: t('post14Text2')}} />
                    </Col>
                    <Col md={4} xxl={3}><ArticleWidget2 /></Col>
                </Row>
            </Container>
        </Layout>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;